import React from "react";
import HelpIcon from "@material-ui/icons/LiveHelp";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { contactDetails } from "../../constants";
import { Panel, PanelHeader } from "../../components/Panel";
import { LocalizedText } from "../../components/LocalizedText";
import { SupportForm } from "../../components/SupportForm";
import { useStyles } from "./styles";

/**
 * DEPRECATED PAGE - Converted Support Nav to use Intercom
 *
 * A page containing a form to email customer support
 * @returns
 */
export const SupportPage: React.FC = () => {
  const classes = useStyles();

  const region = localStorage.getItem("region") || "GB";
  return (
    <Panel
      header={
        <PanelHeader
          title={<LocalizedText text="support" />}
          icon={<HelpIcon />}
        />
      }
    >
      <Typography variant="body1" paragraph>
        <LocalizedText text="support_intro" />
      </Typography>

      <div className={classes.form}>
        <SupportForm />
      </div>

      <Typography variant="body1" paragraph>
        <LocalizedText text="support_contact_intro" />
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>
          <LocalizedText text="phone" />:
        </strong>{" "}
        {contactDetails(region).phone}
        <br />
        <strong>
          <LocalizedText text="fax" />:
        </strong>{" "}
        {contactDetails(region).fax}
        <br />
        <strong>
          <LocalizedText text="email" />:
        </strong>{" "}
        {contactDetails(region).email}
        <br />
      </Typography>

      <Typography variant="body1" paragraph>
        <Link
          href="https://www.ramtracking.com/gdpr/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          <LocalizedText text="privacy_policy" />
        </Link>
      </Typography>
    </Panel>
  );
};
