import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DateIcon from "@material-ui/icons/CalendarToday";
import WarningIcon from "@material-ui/icons/Warning";
import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import {
  combineTracksAndEvents,
  eventsToJourneyPingArray,
  filterEventPings,
  reducePingsToMaxLength,
} from ".";
import { speedColors } from "../../constants";
import { useEvents } from "../../services/api/events";
import { LocalizedButton } from "../Button";
import { MediaStatuses } from "../EventDetail/constants";
import { LocalizedText } from "../LocalizedText";
import {
  EndJourneyMarker,
  MapView,
  PingMarker,
  StartJourneyMarker,
} from "../Map";
import { GroupMarker } from "../Map/GroupMarker/views";
import { VideoStatusIcon } from "../VideoStatusIcon";
import { Status } from "../VideoStatusIcon/interfaces";
import {
  EventPingMarkerProps,
  EventPingProps,
  JourneyMapViewProps,
  MapLegendProps,
} from "./interfaces";
import { JourneyPingMarker } from "./JourneyPing/views";
import {
  useMapLegendStyles,
  usePingPopupStyles,
  useStyles,
  useVideoLegendStyles,
} from "./styles";

export const JourneyMapView: React.FC<JourneyMapViewProps> = ({
  journey,
  vehicle,
  currentUserId,
  loading,
  error,
  onRequestVideo,
}) => {
  const classes = useStyles();
  const [map, setMap] = React.useState<google.maps.Map>();

  const handleGoogleApiLoaded = ({ map }: { map: google.maps.Map }) => {
    setMap(map);
  };

  const handleRequestVideo = (
    date: string,
    location: string,
    heading: number
  ) => {
    return onRequestVideo(date, location, heading);
  };

  React.useEffect(() => {
    if (!map) return;
    if (map.getStreetView().getVisible()) {
      map.getStreetView().setVisible(false);
    }

    /**
     * Create an area encompassing the journey
     */
    const latlngbounds = new google.maps.LatLngBounds();

    journey.tracks.forEach((track) => {
      const [lat, lng] = track.location.split(",");

      latlngbounds.extend({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });
    });

    // Zoom map to fit created area
    map.fitBounds(latlngbounds);
  }, [map, journey]);

  const journeyStartTime = journey.tracks[0]?.time || "";
  const journeyEndTime = journey.tracks[journey.tracks.length - 1].time || "";

  const { data: events, loading: eventsLoading } = useEvents({
    filters: {
      vehicle: [vehicle.id],
      type: [],
      videoOnly: false,
      severity: [],
      date: [journeyStartTime, journeyEndTime],
    },
    sort: "asc",
  });

  const eventPings = events ? eventsToJourneyPingArray(events) : [];
  const filteredEventPings = filterEventPings(eventPings);

  const maxMarkers = reducePingsToMaxLength(journey.tracks);

  const pings = combineTracksAndEvents(maxMarkers, filteredEventPings);

  const markers = pings.map((ping, i) => {
    const [lat, lng] = ping.location.split(",");
    const coord = { lat: parseFloat(lat), lng: parseFloat(lng) };

    if (!ping.isEvent) {
      if (i === 0) return <StartJourneyMarker key={i} {...coord} />;

      if (i === pings.length - 1 && !journey.inProgress) {
        return (
          <EndJourneyMarker data-testid="marker__end" key={i} {...coord} />
        );
      }

      return (
        <JourneyPingMarker
          key={i + coord.lat + coord.lng}
          ping={ping}
          lat={coord.lat}
          lng={coord.lng}
          vehicle={vehicle}
          onRequestVideo={handleRequestVideo}
          currentUserId={currentUserId}
          journeyStartTime={journeyStartTime}
          journeyEndTime={journeyEndTime}
        />
      );
    }

    if (ping.isEvent) {
      return (
        <EventPingMarker
          key={i + coord.lat + coord.lng}
          ping={ping}
          lat={coord.lat}
          lng={coord.lng}
          vehicleId={vehicle.id}
        />
      );
    }
    return null;
  });

  //Group by {lat,lng}
  const groupedMarkers = _.groupBy(
    markers.slice(1, markers.length - 1), //Start and End marker are ineledgible for Grouping
    (m) => `${m?.props.lat},${m?.props.lng}`
  );

  //Wrap markers in a GroupMarker if there are multiple at the same location
  const finalMarkers = Object.keys(groupedMarkers).map((location) => {
    const locationMarkers = groupedMarkers[location];

    if (locationMarkers.length > 1) {
      const [lat, lng] = location.split(",");
      const coord = { lat: parseFloat(lat), lng: parseFloat(lng) };

      return (
        <GroupMarker
          key={"group-" + coord.lat + coord.lng}
          lat={coord.lat}
          lng={coord.lng}
          heading={locationMarkers[0]?.props.ping?.heading}
        >
          {locationMarkers}
        </GroupMarker>
      );
    }
    return locationMarkers[0];
  });
  return (
    <div className={classes.wrapper}>
      <MapView
        center={{ lat: -5, lng: 0 }}
        height="100%"
        onGoogleApiLoaded={handleGoogleApiLoaded}
        loading={loading || eventsLoading}
        error={error}
      >
        {markers[0]}
        {finalMarkers}
        {markers[markers.length - 1]}
      </MapView>
      <MapLegend show={!loading && !error} />
      <VideoLegend show={!loading && !error} />
    </div>
  );
};

const EventPingMarker: React.FC<EventPingMarkerProps> = React.memo(
  ({ ping, lat, lng }) => {
    const classes = usePingPopupStyles();

    const mediaStatus =
      (ping.event?.media?.[0].status as MediaStatuses) ||
      MediaStatuses.requestable;

    return (
      <PingMarker
        lat={lat}
        lng={lng}
        {...ping}
        status={mediaStatus}
        icon={<WarningIcon />}
      >
        <div className={classes.container}>
          <EventPingPopup {...ping} />
        </div>
      </PingMarker>
    );
  }
);

const EventPingPopup: React.FC<EventPingProps> = ({
  time,
  event,
  vehicleId,
}) => {
  const classes = usePingPopupStyles();
  return (
    <div className={classes.container} data-testid="journey-map__ping-marker">
      <List dense>
        <ListItem disableGutters>
          <ListItemIcon classes={{ root: classes.icon }}>
            <DateIcon />
          </ListItemIcon>
          <ListItemText>
            {DateTime.fromISO(time).toLocaleString(
              DateTime.TIME_24_WITH_SECONDS
            )}
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon classes={{ root: classes.icon }}>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>{event.type}</Typography>
          </ListItemText>
        </ListItem>
      </List>
      <LocalizedButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The material typings are incorrect here - they don't include the component prop
        // @ts-ignore
        component={Link}
        to={`/events/${vehicleId}/${event?.id}`}
        fullWidth
      >
        see_event
      </LocalizedButton>
    </div>
  );
};

const MapLegend: React.FC<MapLegendProps> = ({ show }) => {
  const classes = useMapLegendStyles();

  if (!show) return <></>;

  return (
    <div className={classes.legend} aria-label="map__legend">
      <Typography variant={"h6"}>Speed</Typography>
      <div className={classes.legends}>
        <Box padding={0.5} bgcolor={speedColors.speed1}>
          <LocalizedText text="speed1"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed2}>
          <LocalizedText text="speed2"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed3}>
          <LocalizedText text="speed3"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed4}>
          <LocalizedText text="speed4"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed5}>
          <LocalizedText text="speed5"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed6}>
          <LocalizedText text="speed6"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed7}>
          <LocalizedText text="speed7"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed8}>
          <LocalizedText text="speed8"></LocalizedText>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed9}>
          <LocalizedText text="speed9"></LocalizedText>
        </Box>
      </div>
      <LocalizedText text="speed_unit"></LocalizedText>
    </div>
  );
};

const VideoLegend: React.FC<MapLegendProps> = ({ show }) => {
  const classes = useVideoLegendStyles();

  if (!show) return <></>;

  return (
    <div className={classes.legend} aria-label="map__legend">
      <Typography variant={"h6"}>Video</Typography>
      <div className={classes.legends}>
        <Box padding={0.5} bgcolor={speedColors.speed7}>
          <VideoStatusIcon status={Status.PENDING} />
          <Typography>Requested</Typography>
        </Box>
        <Box padding={0.5} bgcolor={speedColors.speed2}>
          <VideoStatusIcon status={Status.AVAILABLE} />
          <Typography>Available</Typography>
        </Box>
      </div>
    </div>
  );
};
