import { Vehicle } from "../../../interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../Button";
import VideocamIcon from "@material-ui/icons/Videocam";

interface LivestreamGoToButtonProps {
  vehicle: Vehicle;
}

const supportedProviders = ["RAM"];

export const LivestreamGoToButton: React.FC<LivestreamGoToButtonProps> = ({
  vehicle,
}) => {
  const history = useHistory();

  if (vehicle.cameras.length === 0) {
    return <></>;
  }
  const provider = vehicle.cameras[0]?.provider;

  if (!supportedProviders.includes(provider)) {
    return <></>;
  }
  if (!vehicle.status?.ignitionState || vehicle.status?.status === "OFFLINE") {
    return <></>;
  }

  const handleGoToLivestreamClick = () => {
    history.push(`/vehicles/${vehicle.id}/stream`);
  };
  return (
    <Button onClick={handleGoToLivestreamClick}>
      <VideocamIcon fontSize="small" style={{ color: "inherit" }} />
      Live Stream
    </Button>
  );
};
