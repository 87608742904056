import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import EventIcon from "@material-ui/icons/Warning";
import Skeleton from "@material-ui/lab/Skeleton";

import { LocalizedText } from "../LocalizedText";
import { CloseButton, Panel, PanelHeader } from "../Panel";
import { CarMarker, MapView } from "../Map";
import { useIsMobileSize } from "../../hooks/is-mobile-size";

import { EventCameraMedia } from "../EventCameraMedia";
import { EventDetailViewProps } from "./interfaces";
import { collateMedia } from "./transformers";
import { useStyles } from "./styles";
import { VideoScrubber } from "../Hyperlapse";

export const EventDetailView: React.FC<EventDetailViewProps> = ({
  event,
  loading,
  error,
  onBack,
}) => {
  const classes = useStyles();

  const [lat, lng] = event?.location
    ? event.location.split(",").map((x: string) => parseFloat(x))
    : [];

  const collatedMedia = event && collateMedia(event.media);

  const { isMobileSize, isTabletSize } = useIsMobileSize();

  const [highlightedChannel, setHighlightedChannel] = React.useState<number>();
  React.useEffect(() => {
    /**
     * Add highlight effect. Use a timeout
     * so that the CSS transition has a chance
     * to apply
     */
    const highlightedChannelParam = parseInt(
      new URLSearchParams(window.location.search).get("highlightChannel") || ""
    );
    if (highlightedChannelParam) {
      setTimeout(() => {
        setHighlightedChannel(highlightedChannelParam);
      }, 1000);
    }

    /**
     * Remove it after a few seconds, once the user
     * has had a chance to notice it
     */
    setTimeout(() => {
      setHighlightedChannel(undefined);
    }, 3000);
  }, []);

  /**
   * Panel header:
   * - Title
   * - Back button
   */
  const header = (
    <div data-testid="driving-event-header">
      <PanelHeader
        icon={<EventIcon />}
        title={
          <>
            {event?.vehicle.registration}{" "}
            {event?.vehicle.driver ? "(" + event?.vehicle.driver + ") " : ""}
            {event?.date}
          </>
        }
      >
        {onBack && <CloseButton onClick={onBack} />}
      </PanelHeader>
    </div>
  );

  return (
    <Panel header={header}>
      {loading && <div data-testid="event-loading" aria-label="Loading"></div>}
      {error && (
        <Alert severity="error">
          <LocalizedText text="error_loading_event" />
        </Alert>
      )}

      {/* Description of Event */}
      <Grid
        className={classes.summary}
        container
        spacing={isMobileSize || isTabletSize ? 0 : 2}
      >
        <Grid item xs={12} md={6}>
          <div>
            {!event ? (
              <Skeleton variant="rect" />
            ) : (
              <>
                <strong>Type:</strong> {event.type}
              </>
            )}
          </div>
          <div>
            {!event ? (
              <Skeleton variant="rect" />
            ) : event.severity ? (
              <>
                <strong>Severity:</strong> {event.severity}
              </>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            {!event ? (
              <Skeleton variant="rect" />
            ) : event.location || event.address ? (
              <>
                <strong>Location:</strong> {event.address || event.location}
              </>
            ) : null}
          </div>
        </Grid>
      </Grid>

      {/* Skeleton loading */}
      {loading && (
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Skeleton variant="rect" height={320} />
          </Grid>
          <Grid item sm={6}>
            <Skeleton variant="rect" height={320} />
          </Grid>
          <Grid item sm={6}>
            <Skeleton variant="rect" height={320} />
          </Grid>
          <Grid item sm={6}>
            <Skeleton variant="rect" height={320} />
          </Grid>
        </Grid>
      )}

      {/* Main content */}
      {!error && !loading && event && event.type !== "Hyperlapse" && (
        <Grid container spacing={1}>
          {collatedMedia?.map((media) => {
            return (
              <Grid key={media[0]?.channel} sm={6} item container>
                <EventCameraMedia
                  highlight={highlightedChannel === media[0]?.channel}
                  channel={media[0]?.channel}
                  cameraName={media[0]?.channelDesc}
                  media={media}
                  showTools
                />
              </Grid>
            );
          })}
          {event.location && (
            <Grid item xs={12} md={6}>
              <Paper data-testid="event-detail__street-view">
                <MapView
                  key={event.location}
                  center={{ lat, lng }}
                  zoom={16}
                  heading={event.heading}
                  streetView
                />

                <Box className={classes.toolbar}>
                  <LocalizedText text="street_view" />
                </Box>
              </Paper>
            </Grid>
          )}
          {event.location && (
            <Grid item xs={12} md={6}>
              <Paper data-testid="event-detail__map">
                <MapView key={event.location} center={{ lat, lng }} zoom={16}>
                  {lat && lng && (
                    <CarMarker lat={lat} lng={lng} heading={event.heading} />
                  )}
                </MapView>

                <Box className={classes.toolbar}>
                  <LocalizedText text="map_view" />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
      {!error && !loading && event && event.type === "Hyperlapse" && (
        <Grid container>
          <Grid item sm={12}>
            <VideoScrubber
              video={event.media[0]?.link || undefined}
              event={event}
            />
          </Grid>
        </Grid>
      )}
    </Panel>
  );
};
