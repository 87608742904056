import { Box } from "@material-ui/core";
import { LocalizedSelect } from "../Input";
import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { CalendarSearchBarProps } from "./interfaces";

const CalendarSearchBar: React.FC<CalendarSearchBarProps> = ({
  vehiclesData,
  setRequestedVehicle,
  setVehicleId,
}) => {
  const classes = useStyles();
  const [vehicle, setVehicle] = React.useState<string>(
    vehiclesData ? vehiclesData[0]?.id : ""
  );
  const { t } = useTranslation();

  const findVehicleFromVehicleId = (vehicleId: string) => {
    const selectedVehicle = vehiclesData?.find(
      (vehicle) => vehicleId === vehicle.id
    );
    if (selectedVehicle) {
      setRequestedVehicle(selectedVehicle);
    }
  };

  return (
    <>
      <div className={classes.toolbar}>
        <Box className={classes.vehicleFilterWrap}>
          <LocalizedSelect
            fullWidth
            id="vehicle"
            name="vehicle"
            value={vehicle}
            label={t("vehicle")}
            onChange={(e) => {
              setVehicle(e.target.value as string);
              setVehicleId(e.target.value as string);
              findVehicleFromVehicleId(e.target.value as string);
            }}
            options={
              vehiclesData?.map((vehicle) => ({
                value: vehicle.id,
                label: vehicle.registration,
              })) || []
            }
          ></LocalizedSelect>
        </Box>
      </div>
    </>
  );
};

export default CalendarSearchBar;
