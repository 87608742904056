import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "../../hooks/i18n";
import { useJourney } from "../../services/api/journeys";
import { MapView } from "../Map";

import { JourneyMapProps } from "./interfaces";
import { JourneyMapView } from "./views";

export const JourneyMap: React.FC<JourneyMapProps> = ({
  vehicle,
  journeyId,
}) => {
  const { t } = useTranslation();
  const { data, loading, error, requestVideoForDate } = useJourney({
    vehicleId: vehicle.id,
    journeyId,
  });

  const { ctxUser } = React.useContext(UserContext);

  const handleRequestVideo = async (
    date: string,
    location: string,
    heading: number
  ): Promise<boolean> => {
    try {
      await requestVideoForDate(date, location, heading);

      return true;
    } catch (e) {
      alert(t("error_requesting_video"));
      return false;
    }
  };

  if (loading || error || !data) {
    return (
      <MapView
        center={{ lat: -5, lng: 0 }}
        height="100%"
        loading={loading}
        error={error}
      />
    );
  }

  return (
    <JourneyMapView
      journey={data}
      vehicle={vehicle}
      loading={loading}
      error={error}
      onRequestVideo={handleRequestVideo}
      currentUserId={ctxUser?.id}
    />
  );
};
