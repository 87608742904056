import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import AccountIcon from "@material-ui/icons/EmojiTransportation";

import { CloseButton, Panel, PanelHeader } from "../Panel";
import { FieldWrapper, LocalizedTextField } from "../Input";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { SkeletonForm } from "../SkeletonForm";

import {
  AccountFormData,
  AccountFormViewProps,
  SubmittingAccountFormData,
} from "./interfaces";
import { useStyles } from "./styles";
import { useTranslation } from "../../hooks/i18n";
import { ProviderAccordion } from "../ProviderAccordion";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Switch } from "@material-ui/core";

type Provider = "MFL" | "RAM";
const providers: Provider[] = ["MFL", "RAM"];
/**
 * Validation rules for the form
 *
 * We have two separate schemas, one for creating, one for updating, This is
 * because when updating, we don't have access to the existing credentials
 * for the account.  The user only needs to enter them if they want to change
 * them.
 */
const baseSchema = {
  name: yup.string().required("account_name_required"),
  salesforceId: yup
    .string()
    .matches(/^001\w{15}$/, "Salesforce ID must be valid")
    .required("salesforce_id_required"),
  enableMultiProviderFeatures: yup.boolean(),
};
const updateAccountSchema = yup.object(baseSchema);
const createAccountSchema = yup.object({
  ...baseSchema,
  providers: yup.object().shape(
    {
      MFL: yup.object().when(["RAM.username", "RAM.password", "RAM.fleetId"], {
        is: (username: string, password: string, fleetId: string) =>
          !username || !password || !fleetId,
        then: yup.object({
          username: yup.string().required("provider_username_required"),
          password: yup.string().required("provider_password_required"),
          fleetId: yup.string().required("provider_fleet_id_required"),
        }),
        otherwise: yup.object({
          username: yup.string(),
          password: yup.string(),
          fleetId: yup.string(),
        }),
      }),
      RAM: yup.object().when(["MFL.username", "MFL.password", "MFL.fleetId"], {
        is: (username: string, password: string, fleetId: string) =>
          !username || !password || !fleetId,
        then: yup.object({
          username: yup.string().required("provider_username_required"),
          password: yup.string().required("provider_password_required"),
          fleetId: yup.string().required("provider_fleet_id_required"),
        }),
        otherwise: yup.object({
          username: yup.string(),
          password: yup.string(),
          fleetId: yup.string(),
        }),
      }),
    },
    [["MFL", "RAM"]]
  ),
});

/**
 * Placeholder to show credentials are present on account but not available for
 * display
 */
const maskedValue = "●●●●●●●●●●";
/**
 * A form for creating or editing an account (fleet)
 */
export const AccountFormView: React.FC<AccountFormViewProps> = ({
  account,
  accountId,
  loading,
  error,
  success,
  isSubmitting,
  onSubmit,
  isEdit,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [showSkeleton, setShowSkeleton] = React.useState(isEdit);

  const formik = useFormik<AccountFormData>({
    initialValues: {
      name: account?.name || "",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- We need to provide an empty value, so this can't be a number (the input API uses strings anyway)
      // @ts-ignore
      salesforceId: "",
      enableMultiProviderFeatures: false,
      providers: {
        MFL: {
          name: "MFL",
          username: "",
          password: "",
          fleetId: "",
        },
        RAM: {
          name: "RAM",
          username: "",
          password: "",
          fleetId: "",
        },
      },
    },
    validateOnBlur: true,
    validationSchema: account ? updateAccountSchema : createAccountSchema,
    onSubmit: (values) => {
      const anyProviderChanged = () => {
        for (const provider of providers) {
          const providerObj = formik.values.providers[provider];
          const { username, password, fleetId } = providerObj;

          if (
            (username !== maskedValue && username !== "") ||
            (password !== maskedValue && password !== "") ||
            (fleetId !== maskedValue && fleetId !== "")
          ) {
            return true;
          }
        }
      };
      const mflChanged = () => {
        const { username, password, fleetId } = formik.values.providers.MFL;
        return (
          (username !== maskedValue && username !== "") ||
          (password !== maskedValue && password !== "") ||
          (fleetId !== maskedValue && fleetId !== "")
        );
      };
      const ramChanged = () => {
        const { username, password, fleetId } = formik.values.providers.RAM;
        return (
          (username !== maskedValue && username !== "") ||
          (password !== maskedValue && password !== "") ||
          (fleetId !== maskedValue && fleetId !== "")
        );
      };
      /**
       * Confirm changes to provider credential fields when editing an existing account
       */

      if (account && anyProviderChanged()) {
        const confirmProviderCredentialChange = window.confirm(
          t("confirm_provider_credential_change")
        );
        if (!confirmProviderCredentialChange) {
          return;
        }
      }

      const finalValues: SubmittingAccountFormData = { ...values };
      /**
       * If the credentials haven't been touched, don't submit them (we don't
       * have the existing values available).
       *
       * If they have been touched, clean the values of any masked values.
       */
      if (isEdit) {
        if (anyProviderChanged()) {
          if (mflChanged()) {
            if (finalValues.providers?.MFL?.username === maskedValue)
              delete finalValues.providers.MFL.username;
            if (finalValues.providers?.MFL?.password === maskedValue)
              delete finalValues.providers.MFL.password;
            if (finalValues.providers?.MFL?.fleetId === maskedValue)
              delete finalValues.providers.MFL.fleetId;
          } else {
            delete finalValues.providers?.MFL;
          }
          if (ramChanged()) {
            if (finalValues.providers?.RAM?.username === maskedValue)
              delete finalValues.providers.RAM.username;
            if (finalValues.providers?.RAM?.password === maskedValue)
              delete finalValues.providers.RAM.password;
            if (finalValues.providers?.RAM?.fleetId === maskedValue)
              delete finalValues.providers.RAM.fleetId;
          } else {
            delete finalValues.providers?.RAM;
          }
        } else {
          delete finalValues.providers;
        }
      }
      onSubmit(finalValues);
    },
  });

  /**a
   * Once account has loaded, fill the form
   */
  React.useEffect(() => {
    if (!account) return;

    formik.setValues(
      {
        name: account.name,
        salesforceId: account.salesforceId,
        enableMultiProviderFeatures: account.enableMultiProviderFeatures,
        /**
         * We don't have the existing credentials available from the API, so we
         * just show a hint that there are some values stored
         */
        providers: {
          MFL: {
            name: "MFL",
            username:
              account.providers.indexOf("MFL") !== -1 ? maskedValue : "",
            password:
              account.providers.indexOf("MFL") !== -1 ? maskedValue : "",
            fleetId: account.providers.indexOf("MFL") !== -1 ? maskedValue : "",
          },
          RAM: {
            name: "RAM",
            username:
              account.providers.indexOf("RAM") !== -1 ? maskedValue : "",
            password:
              account.providers.indexOf("RAM") !== -1 ? maskedValue : "",
            fleetId: account.providers.indexOf("RAM") !== -1 ? maskedValue : "",
          },
        },
      },
      false
    );

    setShowSkeleton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formik changes each render so cannot be declared as a dependency
  }, [account]);

  const isMultiProviderAccount = account && account.providers.length > 1;

  /**
   * Construct header text
   */
  const headerText =
    loading || account ? (
      loading ? (
        <LocalizedText text="loading" />
      ) : (
        <>
          <LocalizedText text="editing" />: {account?.name}
        </>
      )
    ) : (
      <LocalizedText text="create_account" />
    );
  const header = (
    <PanelHeader icon={<AccountIcon />} title={headerText}>
      {account && (
        <CloseButton
          onClick={() => history.push(`/accounts/summary/${accountId}`)}
        />
      )}
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert className={classes.alert} severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}
      {success && (
        <Alert className={classes.alert} severity="info">
          <LocalizedText text="changes_saved" />
        </Alert>
      )}

      {showSkeleton ? (
        <SkeletonForm numFields={7} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6">Details</Typography>
          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="name"
              name="name"
              label="account_name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="salesforceId"
              name="salesforceId"
              label="salesforce_id"
              value={formik.values.salesforceId}
              onChange={formik.handleChange}
              error={
                formik.touched.salesforceId &&
                Boolean(formik.errors.salesforceId)
              }
              helperText={
                formik.touched.salesforceId && formik.errors.salesforceId
              }
            />
          </FieldWrapper>

          {isMultiProviderAccount && (
            <FieldWrapper>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.enableMultiProviderFeatures}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "enableMultiProviderFeatures",
                        event.target.checked
                      );
                    }}
                    name="enableMultiProviderFeatures"
                  />
                }
                label="Enable Hyperlapse/Livestream for this account"
              />
            </FieldWrapper>
          )}

          <FieldWrapper>
            <Typography variant="h6">Configuration</Typography>
            <ProviderAccordion formik={formik} providers={providers} />
          </FieldWrapper>
          <LocalizedButton fullWidth disabled={isSubmitting} type="submit">
            {isSubmitting ? "saving" : "save"}
          </LocalizedButton>
        </form>
      )}
    </Panel>
  );
};
