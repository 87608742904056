import { API } from "aws-amplify";

import { Json } from "../../interfaces";
import { apiName } from "../../constants";

export async function amplifyGet(
  input: string,
  init: RequestInit
): Promise<Json> {
  return API.get(apiName, input, init);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types -- We're wrapping the amplify API here which types init as any. Ideally we would type this ourselves
export async function amplifyPost(input: string, init: any): Promise<Json> {
  return API.post(apiName, input, init);
}

export async function amplifyPut(
  input: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types -- We're wrapping the amplify API here which types init as any. Ideally we would type this ourselves
  init: any
): Promise<void> {
  return API.put(apiName, input, init);
}

export async function amplifyPatch(
  input: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types -- We're wrapping the amplify API here which types init as any. Ideally we would type this ourselves
  init: any
): Promise<void> {
  return API.patch(apiName, input, init);
}

export async function amplifyDelete(
  input: string,
  init: RequestInit
): Promise<void> {
  return API.del(apiName, input, init);
}
