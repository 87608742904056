import React from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import PeopleIcon from "@material-ui/icons/People";

import isEmail from "validator/lib/isEmail";
import { CloseButton, Panel, PanelHeader } from "../Panel";
import { FieldWrapper, LocalizedTextField } from "../Input";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { SkeletonForm } from "../SkeletonForm";

import { UserFormData, UserFormViewProps } from "./interfaces";
import { useStyles } from "./styles";
import { ResetUserStateButton } from "../ResetUserStateButton";
import { Box } from "@material-ui/core";

/**
 * Validation rules for the form
 */
const schema = yup.object({
  firstName: yup
    .string()
    .max(64, "first_name_max_length")
    .required("first_name_required"),
  lastName: yup
    .string()
    .max(64, "last_name_max_length")
    .required("last_name_required"),
  email: yup
    .string()
    .required("email_required")
    .test("validatorJsIsEmail", "email_invalid", (value) => {
      return value ? isEmail(value) : false;
    }),
});

/**
 * A form for creating or editing a user
 */
export const UserFormView: React.FC<UserFormViewProps> = ({
  id,
  user,
  loading,
  error,
  success,
  accountId,
  isSubmitting,
  onSubmit,
  isProfile,
  isEdit,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [showSkeleton, setShowSkeleton] = React.useState(isEdit);

  const formik = useFormik<UserFormData>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      salesforceContactId: "",
    },
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  /**
   * Once user has loaded, fill the form
   */
  React.useEffect(() => {
    if (!user) return;

    formik.setValues(user);

    setShowSkeleton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formik changes each render so cannot be declared as a dependency
  }, [user]);

  /**
   * Construct header text
   */
  const headerText =
    loading || user ? (
      loading ? (
        <LocalizedText text="loading" />
      ) : (
        <>
          <LocalizedText text="editing" />: {user?.firstName} {user?.lastName}
        </>
      )
    ) : (
      <LocalizedText text="create_user" />
    );

  const salesforceContactIdTextBox =
    user && !isProfile ? (
      <FieldWrapper>
        <LocalizedTextField
          fullWidth
          id="salesforceContactId"
          name="salesforceContactId"
          label="salesforce_contact_id"
          value={formik.values.salesforceContactId}
          onChange={formik.handleChange}
        />
      </FieldWrapper>
    ) : (
      <></>
    );
  const header = (
    <PanelHeader icon={<PeopleIcon />} title={headerText}>
      {!isProfile && (
        <Box flexDirection={"row"} display="flex">
          {id && <ResetUserStateButton userId={id} />}
          <CloseButton
            onClick={() => history.push(`/accounts/summary/${accountId}/users`)}
          />
        </Box>
      )}
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert className={classes.alert} severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}
      {success && (
        <Alert className={classes.alert} severity="info">
          <LocalizedText text="changes_saved" />
        </Alert>
      )}

      {showSkeleton ? (
        <SkeletonForm numFields={3} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="firstName"
              name="firstName"
              label="first_name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="lastName"
              name="lastName"
              label="last_name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="email"
              name="email"
              label="email"
              type="email"
              disabled={isProfile}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FieldWrapper>
          {salesforceContactIdTextBox}

          <LocalizedButton fullWidth disabled={isSubmitting} type="submit">
            {isSubmitting ? "saving" : "save"}
          </LocalizedButton>
        </form>
      )}
    </Panel>
  );
};
