import React from "react";

import { Item } from "../ItemList";
import { AccountListView } from "./views";
import { useAccounts } from "../../services/api/accounts";

/**
 * Shows a list of accounts (fleets) and allows:
 * - Navigating to an edit screen for individual accounts
 * - Deleting one or more accounts
 */
export const AccountList: React.FC = () => {
  const { data, loading, error, del, deleting } = useAccounts();
  /**
   * Used to determine whether to show a dismissable badge
   * confirming that a delete operation succeeded
   *
   * TODO: handle error via same mechanism
   */
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);

  const handleDelete = async (items: Item[]) => {
    for (const item of items) {
      await del(item.id);
    }

    setDeleteSuccess(true);
  };

  return (
    <div>
      <AccountListView
        accounts={data}
        loading={loading || deleting}
        error={error}
        onDelete={handleDelete}
        deleteSuccess={deleteSuccess}
      />
    </div>
  );
};
