import { SignIn } from "../../components/Auth";
import { Footer } from "../../components/Footer";

import { useStyles } from "./styles";

export const SignInPage: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.signInContainer}>
        <SignIn />
      </div>
      <Footer />
    </div>
  );
};
