import {
  createResource,
  useCrud,
  UseCrudReturn,
  useResourceList,
  UseResourceListReturn,
  getResource,
  apiGet,
} from "./shared";

import { Json, User } from "../../interfaces";

/**
 * Hook to allow:
 * - retrieving a list of all users for an account from the API
 * - deleting users (with automatic refetching once complete)
 */
export function useUsers(accountId: string): UseResourceListReturn<User> {
  return useResourceList<User>("user", `account/${accountId}/users`);
}

/**
 * Hook to allow:
 * - retrieving a single user from the API by ID
 * - updating that user
 */
export function useUser(id: string): UseCrudReturn<User, User> {
  return useCrud<User, User>({ resource: "user", id });
}

/**
 * Creates an user and refreshes the global state for user lists once
 * done
 */
export async function createUser(data: User): Promise<void> {
  return createResource<User>({ resource: "user", data });
}

/**
 * Gets a User specified by its ID and refreshes the global state for user lists once
 * done
 */
export async function getUser(id: string): Promise<User> {
  return getResource<User>(`user/${id}`);
}

/**
 * Resets user state so that the customer can use the forgot password feature
 */
export async function resetUserState(id: string): Promise<Json | undefined> {
  return apiGet(`/user/${id}/password-reset`);
}
