import React from "react";
import { Layout } from "../Layout";
import { useStyles } from "./styles";
import { useVehiclesForCurrentAccount } from "../../services/api/vehicles";
import { vehicleFilterPageSize } from "./constants";
import { Vehicle } from "../../interfaces";
import CalendarRecordingsList from "../../components/CalendarRecordings/views";
import { useCalendarRecordings } from "../../services/api/calendar";
import CalendarSearchBar from "../../components/CalendarSearchBar/views";
import { TablePaging } from "../../components/TablePaging/views";

export const CalendarPage: React.FC = () => {
  const classes = useStyles();

  const { data: vehiclesData } = useVehiclesForCurrentAccount({
    pageSize: vehicleFilterPageSize,
  });
  const firstVehicle = vehiclesData ? vehiclesData[0] : undefined;

  const [vehicle, setVehicle] = React.useState<Vehicle | undefined>(
    firstVehicle
  );
  const [selectedVehicleId, setSelectedVehicleId] = React.useState<
    string | undefined
  >(firstVehicle?.id);

  const {
    data: recordingsData,
    loading: recordingsLoading,
    error: recordingsError,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useCalendarRecordings(selectedVehicleId);

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.searchBarContainer}>
          <CalendarSearchBar
            vehiclesData={vehiclesData}
            setVehicleId={setSelectedVehicleId}
            setRequestedVehicle={setVehicle}
          />
        </div>
        <CalendarRecordingsList
          loading={recordingsLoading}
          recordings={recordingsData}
          selectedVehicleId={selectedVehicleId}
          vehicle={vehicle}
        />
        <TablePaging
          onNext={nextPage}
          onPrev={prevPage}
          showNext={hasNextPage}
          showPrev={hasPrevPage}
          loading={recordingsLoading && !recordingsError}
        />
      </div>
    </Layout>
  );
};
