import React from "react";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

import { Logo } from "../Logo";
import { LocalizedButton } from "../Button";
import { useStyles } from "./styles";
import {
  CompleteResetPasswordFormProps,
  FormWrapperProps,
  NewPasswordFormProps,
  ResetPasswordFormProps,
  SignInFormProps,
} from "./interfaces";
import { LocalizedSimpleInput } from "../Input";
import { LocalizedText } from "../LocalizedText";

/**
 * Shared wrapper for auth forms
 */
export const FormWrapper: React.FC<FormWrapperProps> = ({
  error,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Logo />
        {error && <Alert severity="warning">{error}</Alert>}
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  );
};

/**
 * Sign in form view
 */
export const SignInForm: React.FC<SignInFormProps> = ({
  onPasswordResetClick,
  onSubmit,
  submitting,
  message,
  error,
}) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const classes = useStyles();

  return (
    <FormWrapper error={error}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(username, password);
        }}
      >
        {message && (
          <Typography className={classes.message}>
            <LocalizedText text={message} />
          </Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LocalizedSimpleInput
              id="username"
              name="username"
              type="email"
              label="email"
              autoComplete="email"
              placeholder="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizedSimpleInput
              id="password"
              name="password"
              type="password"
              label="password"
              autoComplete="current-password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <div>
          <LocalizedButton className={classes.button} type="submit" fullWidth>
            {submitting ? "signing_in" : "sign_in"}
          </LocalizedButton>

          <LocalizedButton
            className={classes.button}
            variant="text"
            fullWidth
            onClick={(e) => {
              /**
               * Don't submit the form
               */
              e.preventDefault();

              onPasswordResetClick(username);
            }}
          >
            forgot_password
          </LocalizedButton>
        </div>
      </form>
    </FormWrapper>
  );
};

/**
 * Force change password form view
 * Shown when a user is required to change their temporary password
 * the first time they sign in
 */
export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  onSubmit,
  error,
}) => {
  const classes = useStyles();
  const [password, setPassword] = React.useState("");

  return (
    <FormWrapper error={error}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(password);
        }}
      >
        <Typography className={classes.message}>
          <LocalizedText text="change_password_required" />
        </Typography>

        <LocalizedSimpleInput
          id="new-password"
          name="new-password"
          type="password"
          label="new_password"
          autoComplete="new-password"
          placeholder="New password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          fullWidth
        />
        <LocalizedButton className={classes.button} type="submit" fullWidth>
          continue
        </LocalizedButton>
      </form>
    </FormWrapper>
  );
};

/**
 * Request password reset email view
 */
export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  username: initialUsername,
  onBackClick,
  onSubmit,
  submitting,
  error,
}) => {
  const classes = useStyles();
  const [username, setUsername] = React.useState(initialUsername);

  return (
    <FormWrapper error={error}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(username);
        }}
      >
        <Typography className={classes.message}>
          <LocalizedText text="reset_password_info" />
        </Typography>

        <LocalizedSimpleInput
          id="username"
          name="username"
          type="text"
          label="email"
          placeholder="email"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          required
          fullWidth
        />

        <LocalizedButton className={classes.button} type="submit" fullWidth>
          {submitting ? "submitting" : "submit"}
        </LocalizedButton>

        <LocalizedButton
          className={classes.button}
          variant="text"
          fullWidth
          onClick={(e) => {
            /**
             * Don't submit the form
             */
            e.preventDefault();

            onBackClick();
          }}
        >
          back_to_signin
        </LocalizedButton>
      </form>
    </FormWrapper>
  );
};

/**
 * Reset password view
 * User will have received an email with a verification code which they must
 * input along with their new password
 */
export const CompleteResetPasswordForm: React.FC<CompleteResetPasswordFormProps> = ({
  onSubmit,
  submitting,
  error,
}) => {
  const classes = useStyles();
  const [code, setCode] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <FormWrapper error={error}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(code, password);
        }}
      >
        <Typography className={classes.message}>
          <LocalizedText text="complete_reset_password_info" />
        </Typography>

        <LocalizedSimpleInput
          id="verification-code"
          name="verification-code"
          type="text"
          label="verification_code"
          placeholder="verification_code"
          onChange={(e) => setCode(e.target.value)}
          value={code}
          fullWidth
        />
        <LocalizedSimpleInput
          id="new-password"
          name="new-password"
          type="password"
          label="new_password"
          autoComplete="new-password"
          placeholder="New password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          fullWidth
        />
        <LocalizedButton className={classes.button} type="submit" fullWidth>
          {submitting ? "submitting" : "submit"}
        </LocalizedButton>
      </form>
    </FormWrapper>
  );
};
