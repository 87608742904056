import React from "react";
import useSwr, { mutate, useSWRInfinite } from "swr";
import { requestBodyToResponseBody } from "../../components/AccountForm/transformers";

import { AccountUpdateBody, Json } from "../../interfaces";
import {
  amplifyGet,
  amplifyPost,
  amplifyPut,
  amplifyPatch,
  amplifyDelete,
} from "./fetcher";

/**
 * Allow overriding amplify network functions
 *
 * This is useful for avoiding having to setup Amplify in tests and Storybook
 * (responses should be mocked with MSW anyway, but Amplify will error if
 * calls are made using its API Gateway SDK when it hasn't been configured)
 */
type Fetcher = (input: string, init: RequestInit) => Promise<Json>;
type VoidFetcher = (input: string, init: RequestInit) => Promise<void>;
let get = amplifyGet;
let post = amplifyPost;
let put = amplifyPut;
let patch = amplifyPatch;
let del = amplifyDelete;
export function setGet(newFetcher: Fetcher): void {
  get = newFetcher;
}
export function setPost(newFetcher: Fetcher): void {
  post = newFetcher;
}
export function setPut(newFetcher: VoidFetcher): void {
  put = newFetcher;
}
export function setPatch(newFetcher: VoidFetcher): void {
  patch = newFetcher;
}
export function setDelete(newFetcher: VoidFetcher): void {
  del = newFetcher;
}

/**
 * Convenience hook for calling our backend to fetch data only.
 * Uses useSwr from swr under the hood
 * @see https://swr.vercel.app/docs
 *
 * @param {string} url: endpoint to call, including query string but not the base URL, e.g. /events?sort=asc
 */
export function useApiGet<T = Json>(
  url: string
): [T | undefined, boolean, boolean, () => Promise<boolean>] {
  const { data, error, revalidate } = useSwr(`${url}`, apiGet);
  const loading = !data && !error;

  if (process.env.NODE_ENV === "development" && error) {
    console.error("api error!", error);
  }

  return [(data as unknown) as T, loading, error, revalidate];
}

/**
 * Convenience hook for calling our backend to fetch paged data
 * Uses useSwrInfinite from swr under the hood
 * @see https://swr.vercel.app/docs/pagination
 *
 * @param {string} url: endpoint to call, including query string but not the base URL, e.g. /events?sort=asc
 */
interface UseApiGetPagedReturn<T> {
  data: T | undefined;

  loading: boolean;
  error: boolean;
  nextPage: () => void;
  prevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  revalidate: () => Promise<boolean>;
}
interface DataPage {
  items: Json;
  paginationToken: string;
}
export function useApiGetPaged<T = Json>(url: string): UseApiGetPagedReturn<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Typescript crashes if we try and define a sensible type for previousPageData here...
  const getKey = (pageIndex: number, previousPageData: any) => {
    // reached the end
    if (previousPageData && !previousPageData.items) return null;

    // first page, we don't have `previousPageData`
    if (pageIndex === 0) return url;

    // add the cursor to the API endpoint
    const tokenQueryParam = url.includes("?") ? "&token" : "?token";
    return `${url}${tokenQueryParam}=${previousPageData?.paginationToken}`;
  };

  const { data, error, size, setSize, revalidate } = useSWRInfinite(
    getKey,
    apiGet
  );

  /**
   * Infer loading state from lack of data and error
   */
  const loading = !data && !error;

  /**
   * We can infer whether next/prev page is loading based on whether the
   * currently requested page size is greater than the currently available
   * number of pages
   */
  const loadingMore =
    size > 0 && Boolean(data) && typeof data?.[size - 1] === "undefined";

  /**
   * SWR returns all the pages in the range retrieved so far.
   * We're only interested in the latest page retrieved
   */
  const latestPage = (data?.[data.length - 1] as unknown) as
    | DataPage
    | undefined;

  /**
   * Determine whether there is a next page available to request based on the
   * presence of a pagination token in the API response
   */
  const hasNextPage = Boolean(latestPage?.paginationToken);
  /**
   * Determine whether there is a previous page available to request based on
   * the current page size
   */
  const hasPrevPage = data ? data.length > 1 : false;

  if (process.env.NODE_ENV === "development" && error) {
    console.error("api error!", error);
  }

  function nextPage() {
    if (!hasNextPage) return;
    setSize(size + 1);
  }

  function prevPage() {
    if (!hasPrevPage) return;
    setSize(size - 1);
  }

  return {
    data: (latestPage?.items as unknown) as T | undefined,
    loading: loading || loadingMore,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    revalidate,
  };
}

export function apiPost(
  resource: string,
  body: Json
): ReturnType<typeof amplifyPost> {
  return post(resource, {
    body: body,
    headers: { "Content-Type": "application/json" },
  });
}

export function apiPut(
  resource: string,
  body: Json
): ReturnType<typeof amplifyPut> {
  return put(resource, {
    body: body,
    headers: { "Content-Type": "application/json" },
  });
}

export function apiPatch(
  resource: string,
  body: Json
): ReturnType<typeof amplifyPatch> {
  return patch(resource, { body });
}

export function apiGet(resource: string): ReturnType<typeof amplifyGet> {
  return get(resource, {});
}

export function apiDelete(resource: string): ReturnType<typeof amplifyDelete> {
  return del(resource, {});
}

interface UseCrudParams {
  /**
   * The name of the resource as defined by the API, e.g. account
   */
  resource: string;
  /**
   * The plural version of the resource name as defined by the API.
   * If omitted, defaults to the value of `resource` plus "s"
   */
  resourcePlural?: string;
  /**
   * The ID of the resource to fetch and update
   */
  id: string;
}
export interface UseCrudReturn<GetType, UpdateType> {
  data?: GetType;
  loading?: boolean;
  error?: boolean;
  update: (data: UpdateType) => Promise<void>;
  isUpdating: boolean;
  updateSuccess: boolean;
}
/**
 * Hook to retrieve a single item from the API by ID, as well as updating it
 *
 * - Provides all loading/error/success state
 * - Revalidates list state for the same resource type after updating the item
 */
export function useCrud<GetType, UpdateType>({
  resource,
  resourcePlural = `${resource}s`,
  id,
}: UseCrudParams): UseCrudReturn<GetType, UpdateType> {
  const url = `/${resource}/${id}`;
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const [data, loading, error] = useApiGet(url);

  /**
   * Updates the resource
   */
  async function update(data: UpdateType) {
    setIsUpdating(true);
    try {
      /**
       * Perform an optimistic local update, to avoid flickering of old
       * content, and to make the update feel faster
       */
      // This is the cache key for the resource we're updating
      const key = `/${resource}/${id}`;
      /**
       * Some API resources, like accounts, have a slightly different shape
       * depending on whether they're being created/updated or fetched. For
       * the optimistic to work, we need to transform them into the shape
       * they would be in when fetched (from what they are right now - the
       * create/update shape)
       */
      const localData =
        resource === "account"
          ? requestBodyToResponseBody((data as unknown) as AccountUpdateBody)
          : data;
      // Do the optimistic update
      mutate(key, localData, false);

      /**
       * Perform the API request
       */
      await apiPut(key, (data as unknown) as Json);

      setIsUpdating(false);
      setUpdateSuccess(true);
      setUpdateError(false);

      /**
       * Revalidate the local cache to ensure data is synced with server state
       */
      mutate(key);

      /**
       * Revalidate any related collection resources. This means that any lists
       * containing the item being created/updated will update to reflect the
       * latest changes
       */
      mutate(`/${resourcePlural}`);
    } catch (e) {
      console.error("Error updating item", e);
      setUpdateSuccess(false);
      setUpdateError(true);
      setIsUpdating(false);
      return;
    }
  }

  /**
   * Reset state that isn't managed by useApiGet when id changes
   * (i.e. different account requested)
   */
  React.useEffect(() => {
    setIsUpdating(false);
    setUpdateSuccess(false);
    setUpdateError(false);
  }, [id]);

  return {
    data: (data as unknown) as GetType,
    loading,
    error: error || updateError,
    update,
    isUpdating,
    updateSuccess,
  };
}

export interface UseResourceListReturn<GetType> {
  data?: GetType[];
  loading?: boolean;
  error?: boolean;
  del: (id: string) => ReturnType<typeof apiDelete>;
  /**
   * Having a separate loading state for deleting allows
   * better UX, e.g. showing loading indicator next to
   * items being deleted
   */
  deleting?: boolean;
}
/**
 * Hook to retrieve a list of items from the API, as well as deleting any of
 * them
 *
 * - Provides all loading/error/success state
 * - Revalidates list state after deleting item(s)
 */
export function useResourceList<GetType>(
  resource: string,
  resourcePlural = `${resource}s`
): UseResourceListReturn<GetType> {
  // Fetch and store accounts
  const url = `/${resourcePlural}`;
  const [data, loading, error, revalidate] = useApiGet(url);
  /**
   * Loading state for delete operations
   */
  const [deleting, setDeleting] = React.useState<boolean>(false);

  /**
   * Deletes an item
   * TODO: add a delMultiple function to avoid re-rendering after each delete
   * succeeds
   */
  async function del(id: string): Promise<void> {
    setDeleting(true);

    // TODO: handle errors (setDeleteError?)
    await apiDelete(`/${resource}/${id}`);

    // Re-fetch items
    await revalidate();
    setDeleting(false);
  }

  return {
    data: data as GetType[] | undefined,
    loading,
    error,
    del,
    deleting,
  };
}

export interface UseResourceListPagedReturn<GetType>
  extends UseResourceListReturn<GetType> {
  nextPage: () => void;
  prevPage: () => void;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
}
/**
 * Hook to retrieve a paged list of items from the API, as well as deleting any of
 * them
 *
 * - Provides all loading/error/success state
 * - Revalidates list state after deleting item(s)
 */
export function useResourceListPaged<GetType>(
  resource: string,
  resourcePlural = `${resource}s`
): UseResourceListPagedReturn<GetType> {
  // Fetch and store accounts
  const url = `/${resourcePlural}`;
  const {
    data,
    loading,
    error,
    revalidate,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useApiGetPaged(url);
  /**
   * Loading state for delete operations
   */
  const [deleting, setDeleting] = React.useState<boolean>(false);

  /**
   * Deletes an item
   * TODO: add a delMultiple function to avoid re-rendering after each delete
   * succeeds
   */
  async function del(id: string): Promise<void> {
    setDeleting(true);

    // TODO: handle errors (setDeleteError?)
    await apiDelete(`/${resource}/${id}`);

    // Re-fetch items
    await revalidate();
    setDeleting(false);
  }

  return {
    data: data as GetType[] | undefined,
    loading,
    error,
    del,
    deleting,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  };
}

interface CreateResourceParams<UpdateType> {
  data: UpdateType;
  resource: string;
  resourcePlural?: string | null;
}
/**
 * Creates a resource on the API
 *
 * Also re-fetches the state of the list for this resource (e.g. when creating
 * a vehicle, the "get all vehicles" endpoint will be re-fetched). If there is
 * no corresponding list resource to update, pass `resourcePlural: null`
 *
 * You must handle your own loading/error/success states, though in future we
 * might create a hook to encapsulate such boilerplate
 */
export async function createResource<UpdateType>({
  data,
  resource,
  resourcePlural = `${resource}s`,
}: CreateResourceParams<UpdateType>): Promise<void> {
  await apiPost(`/${resource}`, (data as unknown) as Json);
  if (resourcePlural) mutate(`/${resourcePlural}`);
}

interface UpdateResourceParams<UpdateType> {
  data: UpdateType;
  resource: string;
}
/**
 * Updates a resource on the API. Only required for resources that are
 * update-only, e.g. account alert settings. Otherwise you should use the
 * useCrud hook which handles everything for you.
 *
 * Using this function you must handle your own loading/error/success states,
 * though in future we might create a hook to encapsulate such boilerplate
 */
export async function updateResource<UpdateType>({
  data,
  resource,
}: UpdateResourceParams<UpdateType>): Promise<void> {
  await apiPut(`/${resource}`, (data as unknown) as Json);
}

export async function getResource<UpdateType>(
  resource: string
): Promise<UpdateType> {
  return ((await apiGet(`/${resource}`)) as unknown) as UpdateType;
}
