import React from "react";

import {
  completeNewPassword,
  requestPasswordReset,
  completePasswordReset,
  signIn,
  signOut,
} from "../../services/auth";
import { LocalizedButton } from "../Button";
import { CognitoUserWithAttributes } from "../../hooks/auth";
import {
  SignInForm,
  NewPasswordForm,
  ResetPasswordForm,
  CompleteResetPasswordForm,
} from "./views";
import { SignInProps } from "./interfaces";

/**
 * Sign in form container
 * Shows either the sign in or force change new password forms
 */
export const SignIn: React.FC<SignInProps> = ({ onSignIn }) => {
  const signInError =
    "The username and/or password are incorrect. Please try again.";
  const passwordResetError =
    " Your submission has been received. If we have an account matching your email address, you will receive an email with a link to reset your password.";
  const [form, setForm] = React.useState("signIn");
  const [username, setUsername] = React.useState("");
  const [user, setUser] = React.useState<CognitoUserWithAttributes>();
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  /**
   * Useful for persisting messages from one form to another, e.g. when moving
   * from password reset to sign in
   */
  const [message, setMessage] = React.useState("");

  async function handleSignIn(username: string, password: string) {
    setSubmitting(true);
    try {
      const user = await signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user);
        setSubmitting(false);
        setForm("newPassword");
        return;
      }

      if (onSignIn) onSignIn(user);
    } catch (e) {
      setError(signInError);
      setSubmitting(false);
    }
  }

  async function handleSubmitNewPassword(password: string) {
    if (!user) throw new Error("New password invoked without user");
    try {
      const finalUser = await completeNewPassword(user, password);
      if (onSignIn) onSignIn(finalUser);
    } catch (e) {
      setError(e.message);
    }
  }

  /**
   * Show the reset password form when button pressed
   */
  function handlePasswordResetClick(username: string) {
    setUsername(username);
    setForm("resetPassword");
  }

  /**
   * Submit the reset password request and show the change password form when
   * the reset password form has been submitted
   */
  async function handlePasswordResetSubmit(username: string) {
    setSubmitting(true);
    try {
      await requestPasswordReset(username);
    } catch (e) {
      setSubmitting(false);
    }

    setError("");
    setSubmitting(false);
    setUsername(username);
    setForm("completeResetPassword");
  }

  /**
   * Submit the complete reset password request and return the user to sign in
   * form once done
   */
  async function handlePasswordResetCompleteSubmit(
    code: string,
    password: string
  ) {
    setSubmitting(true);
    try {
      await completePasswordReset(username, code, password);
    } catch (e) {
      setSubmitting(false);
      setError(passwordResetError);
      return;
    }

    setError("");
    setSubmitting(false);
    setMessage("password_reset_success");
    setForm("signIn");
  }

  /**
   * Show the relevant form for the current sign in state
   */
  if (form === "signIn")
    return (
      <SignInForm
        onPasswordResetClick={handlePasswordResetClick}
        onSubmit={handleSignIn}
        submitting={submitting}
        error={error}
        message={message}
      />
    );
  if (form === "newPassword")
    return <NewPasswordForm onSubmit={handleSubmitNewPassword} error={error} />;
  if (form === "resetPassword")
    return (
      <ResetPasswordForm
        username={username}
        onBackClick={() => setForm("signIn")}
        onSubmit={handlePasswordResetSubmit}
        submitting={submitting}
        error={error}
      />
    );
  if (form === "completeResetPassword")
    return (
      <CompleteResetPasswordForm
        onSubmit={handlePasswordResetCompleteSubmit}
        submitting={submitting}
        error={error}
      />
    );

  return null;
};

/**
 * Sign out button container
 */
export const SignOut: React.FC = () => {
  return (
    <LocalizedButton type="submit" onClick={() => signOut()}>
      sign_out
    </LocalizedButton>
  );
};
