import { createResource } from "./shared";

import { SupportRequest } from "../../interfaces";

/**
 * Creates a support request
 */
export async function createSupportRequest(
  data: SupportRequest
): Promise<void> {
  return createResource<SupportRequest>({
    resource: "support",
    resourcePlural: null,
    data,
  });
}
