import React from "react";
import { DateTime } from "luxon";

import { DrivingEvent, HyperlapseRequestBody, Json } from "../../interfaces";
import { Filters } from "../../components/EventFilters";
import { Sort } from "../../components/EventList";
import { useApiGet, apiGet, useApiGetPaged, apiPost } from "./shared";
import { v4 as uuid } from "uuid";
import {
  MediaStatuses,
  MediaTypes,
} from "../../components/EventDetail/constants";
import { useCurrentUser } from "../../hooks/auth";

interface UseEvents {
  filters: Filters;
  sort: Sort;
}
/**
 * Builds a URL to fetch driving events, applying the provided
 * filters and sort
 */
export function buildEventsUrl({ filters, sort }: UseEvents): string {
  let url = "/events";
  const filterStrings: string[] = [];
  // Event type
  if (filters.type.length) {
    filterStrings.push(filters.type.map((value) => `type=${value}`).join("&"));
  }
  // Event severity
  if (filters.severity.length) {
    filterStrings.push(
      filters.severity.map((value) => `severity=${value}`).join("&")
    );
  }
  // Vehicle(s)
  if (filters.vehicle.length) {
    filterStrings.push(
      filters.vehicle.map((value) => `vehicle=${value}`).join("&")
    );
  }
  // Date range
  const [start, end] = filters.date;
  if (start) {
    filterStrings.push(`start=${DateTime.fromISO(start).toUTC().toISO()}`);
  }
  if (end) {
    filterStrings.push(`end=${DateTime.fromISO(end).toUTC().toISO()}`);
  }
  if (filters.videoOnly) {
    filterStrings.push(`videoOnly=${filters.videoOnly}`);
  }
  // Sorting
  filterStrings.push(`sort=${sort}`);

  // Build final string
  const filterString = filterStrings.join("&");
  if (filterString) url = `${url}?${filterString}`;

  return url;
}

/**
 * Hook to retrieve driving events from the API, optionally
 * with filters and/or sort
 */
interface UseEventsReturn {
  data: DrivingEvent[];
  loading: boolean;
  error: boolean;
  nextPage: () => void;
  prevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}
export function useEvents({ filters, sort }: UseEvents): UseEventsReturn {
  // Fetch and store events
  const url = buildEventsUrl({ filters, sort });
  const {
    data,
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useApiGetPaged(url);

  return {
    data: (data as unknown) as DrivingEvent[],
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  };
}

interface UseEvent {
  vehicleId: string;
  eventId: string;
}
/**
 * Builds a URL to fetch a driving event, using vehicle and event ID
 */
function buildEventUrl({ vehicleId, eventId }: UseEvent): string {
  return `/event/${vehicleId}/${eventId}`;
}
/**
 * Hook to retrieve an individual driving event from the API
 * Also provides functions to request media for the event.
 */
export function useEvent({
  vehicleId,
  eventId,
}: UseEvent): {
  event: DrivingEvent | undefined;
  loading: boolean;
  error: boolean;
  requestImage: (channel: number) => void;
  requestVideo: (channel: number) => void;
} {
  const { user } = useCurrentUser();
  /**
   * A list of the channels for which a HTTP media request
   * is in progress
   */
  const [imageRequesting, setImageRequesting] = React.useState<number[]>([]);
  const [videoRequesting, setVideoRequesting] = React.useState<number[]>([]);

  /**
   * Reset local state when switching events
   */
  React.useEffect(() => {
    setImageRequesting([]);
    setVideoRequesting([]);
  }, [eventId]);

  /**
   * Fetch and localize event data
   */
  const url = buildEventUrl({ vehicleId, eventId });
  const [data, loading, error, revalidate] = useApiGet<DrivingEvent>(url);

  /**
   * Call the API to request that an image or video be requested from
   * the camera for the given channel
   */
  async function requestMedia(
    type: MediaTypes.image | MediaTypes.video,
    channel: number
  ) {
    /**
     * Add a flag to indicate we should optimistically update the local
     * state with a PENDING status
     */
    if (type === MediaTypes.image)
      setImageRequesting([...imageRequesting, channel]);
    if (type === MediaTypes.video)
      setVideoRequesting([...videoRequesting, channel]);

    /**
     * Make API request
     *
     * If it fails, we will refetch the event to restore the state
     * of the event to reset the optimistic update we made above
     * (this saves us storing the original state somewhere - performance
     * is not a concern here as this is an edge case)
     */
    try {
      await apiGet(
        `/event/${vehicleId}/${eventId}/${
          type === MediaTypes.image ? "image" : "video"
        }?channel=${channel}`
      );
    } catch (e) {
      console.error(`Error while requesting ${type} media`, e);
      /**
       * Remove flag indicating we should apply a local state change,
       * and re-fetch event to restore state
       */
      if (type === MediaTypes.image)
        setImageRequesting(imageRequesting.filter((c) => c !== channel));
      if (type === MediaTypes.video)
        setVideoRequesting(videoRequesting.filter((c) => c !== channel));

      await revalidate();
    }
  }

  async function requestImage(channel: number) {
    requestMedia(MediaTypes.image, channel);
  }

  async function requestVideo(channel: number) {
    requestMedia(MediaTypes.video, channel);
  }

  /**
   * If necessary, optimistically update local state with the fact that
   * the media request is PENDING
   */
  if (data) {
    data.media = data.media.map((item) => {
      if (
        (item.mediaType === MediaTypes.image &&
          imageRequesting.includes(item.channel)) ||
        (item.mediaType === MediaTypes.video &&
          videoRequesting.includes(item.channel))
      ) {
        item.status = MediaStatuses.pending;
        item.requestedBy = user;
      }
      return item;
    });
  }

  return { event: data, loading, error, requestImage, requestVideo };
}

/**
 * Builds a URL to fetch a driving event, using vehicle and event ID
 */
function buildHyperlapseUrl(): string {
  return `/event/hyperlapse`;
}

export function requestHyperlapse(
  request: HyperlapseRequestBody
): Promise<Json> {
  const url = buildHyperlapseUrl();
  const body = {
    id: uuid(),
    vehicleId: request.vehicleId,
    startDate: request.startDate,
    endDate: request.endDate,
    channel: request.channel,
    ...(request.startHeading && { startHeading: request.startHeading }),
    ...(request.startLocation && { startLocation: request.startLocation }),
    ...(request.startAddress && { startAddress: request.startAddress }),
    ...(request.journeyId && { journeyId: request.journeyId }),
  };

  return apiPost(url, body);
}

const buildVoDUrl = () => {
  return `/event/vod`;
};
export function requestVod(request: {
  vehicleId: string;
  date: string;
  location: string | undefined;
  heading: number;
  address: string | null;
  utc: string;
  channel: number;
}): Promise<Json> {
  const url = buildVoDUrl();
  const body = {
    id: uuid(),
    vehicleId: request.vehicleId,
    date: request.date,
    ...(request.location && { location: request.location }),
    ...(request.address && { address: request.address }),
    heading: request.heading,
    utc: request.utc,
    channel: request.channel,
  };

  console.log("requestVod:", body);

  return apiPost(url, body);
}
