import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";

import { FieldWrapper, LocalizedTextField } from "../Input";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { SupportRequest } from "../../interfaces";

import { SupportFormViewProps } from "./interfaces";
import { useStyles } from "./styles";

/**
 * Validation rules for the form
 */
const schema = yup.object({
  subject: yup.string().required("subject_required"),
  message: yup.string().required("message_required"),
});

/**
 * A form for creating or editing a user
 */
export const SupportFormView: React.FC<SupportFormViewProps> = ({
  onSubmit,
  isSubmitting,
  error,
}) => {
  const classes = useStyles();

  const formik = useFormik<SupportRequest>({
    initialValues: {
      subject: "",
      message: "",
    },
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {error && (
        <Alert className={classes.alert} severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}

      <FieldWrapper>
        <LocalizedTextField
          fullWidth
          id="subject"
          name="subject"
          label="subject"
          value={formik.values.subject}
          onChange={formik.handleChange}
          error={formik.touched.subject && Boolean(formik.errors.subject)}
          helperText={formik.touched.subject && formik.errors.subject}
        />
      </FieldWrapper>

      <FieldWrapper>
        <LocalizedTextField
          fullWidth
          id="message"
          name="message"
          label="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
          multiline
          rows="20"
        />
      </FieldWrapper>

      <LocalizedButton fullWidth disabled={isSubmitting} type="submit">
        {isSubmitting ? "sending_request" : "send_request"}
      </LocalizedButton>
    </form>
  );
};

export const SupportFormSuccessView: React.FC = () => {
  return (
    <Alert severity="success">
      <LocalizedText text="support_message_sent" />
    </Alert>
  );
};
