import Tooltip from "@material-ui/core/Tooltip";
import React, { useState } from "react";
import { resetUserState } from "../../services/api/users";
import { LocalizedButton } from "../Button";
import { ResetUserStateButtonProps } from "./interfaces";

export const ResetUserStateButton: React.FC<ResetUserStateButtonProps> = ({
  userId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<undefined | string>();

  const handleSubmit = () => {
    setError(undefined);
    setSubmitting(true);

    if (window.confirm("Are you sure you want to reset the user state?")) {
      resetUserState(userId)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          setError(err);
          console.error(err);
        })
        .finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
    }
  };
  return (
    <Tooltip title="Confirms the user so they are able to reset their password">
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <LocalizedButton onClick={handleSubmit} disabled={submitting}>
          {error ? error : "reset_state"}
        </LocalizedButton>
      </div>
    </Tooltip>
  );
};
