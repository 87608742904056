import React from "react";
import { Hub } from "aws-amplify";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { getUser } from "../services/api/users";
import { UserContext } from "../contexts/UserContext";
import { useCallback } from "react";
import { initIntercomWithUser, shutdown } from "../services/intercom";

export interface CognitoUserWithAttributes extends Partial<CognitoUser> {
  attributes: {
    sub: string;
    email_verified: boolean;
    "custom:account": string;
    "custom:user": string;
    given_name: string;
    family_name: string;
    email: string;
  };
  challengeName?: string;
}

export interface User {
  id: string;
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  salesforceContactId?: string;
  roles?: string[];
}

/**
 * Extracts relevant user details from a CognitoUser
 */
function userFromCognitoUser(user: CognitoUserWithAttributes) {
  return {
    id: user.attributes["custom:user"],
    accountId: user.attributes["custom:account"],
    email: user.attributes.email,
    firstName: user.attributes.given_name,
    lastName: user.attributes.family_name,
    roles: user.getSignInUserSession?.()?.getIdToken().payload[
      "cognito:groups"
    ],
  };
}

/**
 * Extracts relevant user details from a CognitoUser
 */
export function combineCognitoAndDbUser(cognitoUser: User, dbUser: User): User {
  return {
    id: cognitoUser.id,
    email: dbUser.email,
    accountId: dbUser.accountId,
    firstName: dbUser.firstName,
    lastName: dbUser.lastName,
    salesforceContactId: dbUser.salesforceContactId,
    roles: cognitoUser.roles,
  };
}

/**
 * Provides the details of the currently signed-in user
 */
export function useCurrentUser(): {
  user: User | undefined;
  revalidate: () => Promise<void>;
} {
  const [user, setUser] = React.useState<User>();

  const { ctxUser, setCtxUser } = React.useContext(UserContext);

  const revalidate = useCallback(async () => {
    if (!user) {
      shutdown();
      return;
    }
    const combined = combineCognitoAndDbUser(user, await getUser(user.id));
    setCtxUser(combined);
    initIntercomWithUser(combined);
  }, [setCtxUser, user]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    revalidate();
  }, [user, revalidate, setCtxUser]);

  React.useEffect(() => {
    // Get initially signed-in user
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const cognitoUser = userFromCognitoUser(user);
        getUser(cognitoUser.id).then((dbUser) => {
          const combined = combineCognitoAndDbUser(cognitoUser, dbUser);
          setUser(combined);
        });
      })
      .catch((_) => {
        // User not logged in - not a problem, but we don't know until we check
      });

    /**
     * Listen for auth events and update user as necessary
     */
    Hub.listen("auth", async (data) => {
      const { event } = data.payload;
      if (event === "signIn") {
        const user = await Auth.currentAuthenticatedUser();
        const cognitoUser = userFromCognitoUser(user);
        setUser(cognitoUser);
      }
      if (event === "signOut") {
        setUser(undefined);
      }
    });
  }, []);
  return { user: ctxUser, revalidate };
}

/**
 * Provides the session details of the currently signed-in
 * user. Most useful for retrieving the idToken required
 * to make authenticated API calls
 */
export function useIdToken(): [string, boolean] {
  const [idToken, setIdToken] = React.useState<string>("");
  React.useEffect(() => {
    Auth.currentSession().then((data) => {
      setIdToken(data.getIdToken().getJwtToken());
    });
  }, []);

  return [idToken, !idToken];
}
