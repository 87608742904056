import Amplify, { Auth } from "aws-amplify";
import { apiName, apiUrl } from "./constants";

/**
 * Configures the AWS Amplify SDK to point to our backend services
 */
export function configureAmplify(): void {
  Amplify.configure({
    /**
     * Auth config - required to enable signing in via the SDK
     */
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AWS_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
    /**
     * API gateway config - required to make API requests via the SDK
     */
    API: {
      endpoints: [
        {
          name: apiName,
          endpoint: apiUrl,
          /**
           * Send the user's ID token as Authorization header, as expected
           * by our backend
           */
          custom_header: async () => {
            const headers: { [key: string]: string } = {};
            headers["Authorization"] = (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
            const impersonationId = localStorage.getItem(
              "impersonation-accountid"
            );
            if (impersonationId) {
              headers["x-impersonation-accountid"] = impersonationId;
            }
            return headers;
          },
        },
      ],
    },
  });
}
