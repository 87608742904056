import React from "react";

import { AccountAlert } from "../../interfaces";
import { useAccount } from "../../services/api/accounts";
import { AlertSettingsView } from "./views";
import { EditAlertSettingsProps } from "./interfaces";
import { useCurrentUser } from "../../hooks/auth";
import { useUsers } from "../../services/api/users";

/**
 * A container to handle updating alert settings for an account
 *
 * It uses the account for which the current user is signed in
 */
export const AlertSettingsForm: React.FC = () => {
  const { user } = useCurrentUser();

  if (!user) return null;

  return <EditAlertSettings accountId={user.accountId} />;
};

const EditAlertSettings: React.FC<EditAlertSettingsProps> = ({ accountId }) => {
  const {
    data,
    loading,
    error,
    updateAlertSettings,
    isUpdating,
    updateSuccess,
  } = useAccount(accountId);

  const { data: users, loading: usersLoading } = useUsers(accountId);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: AccountAlert) => {
    updateAlertSettings(values);
  };

  return (
    <AlertSettingsView
      values={data?.alerts}
      users={users}
      accountId={data?.id}
      loading={loading || usersLoading}
      error={error}
      onSubmit={handleSubmit}
      isSubmitting={isUpdating}
      success={updateSuccess}
    />
  );
};
