import { Box } from "@material-ui/core";
import CarIcon from "@material-ui/icons/DriveEta";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CameraBadge } from "../../components/CameraBadge/views";
import { LocalizedText } from "../../components/LocalizedText";
import { CloseButton, Panel, PanelHeader } from "../../components/Panel";
import { useVehicle } from "../../services/api/vehicles";
import { Layout } from "../Layout";
import { useStyles } from "./styles";
import { Livestream } from "../../components/Livestream";

interface LivestreamPageParams {
  vehicleId: string;
}

/**
 * Page to allow a user to view a livestream of a specific vehicle
 */
export const LivestreamPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { vehicleId } = useParams<LivestreamPageParams>();

  const { data: vehicle } = useVehicle(vehicleId);

  const handleBack = () => {
    history.push(`/vehicles/${vehicleId}`);
  };

  const title = vehicle ? (
    <Box display="flex" alignItems="center">
      {vehicle.registration}
      <Box display="inline-block" marginLeft={2}>
        <CameraBadge vehicle={vehicle} useTooltip />
      </Box>
    </Box>
  ) : (
    <LocalizedText text="loading" />
  );

  return (
    <Layout menuCollapsed>
      <div className={classes.container}>
        <Panel
          header={
            <PanelHeader icon={<CarIcon />} title={title}>
              <CloseButton onClick={handleBack} />
            </PanelHeader>
          }
        >
          {vehicle && <Livestream camera={vehicle.cameras[0]} />}
        </Panel>
      </div>
    </Layout>
  );
};
