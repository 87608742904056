import React from "react";
import { SupportRequest } from "../../interfaces";
import { createSupportRequest } from "../../services/api/support";

import { SupportFormSuccessView, SupportFormView } from "./views";

/**
 * A form to allow customers to contact support
 */
export const SupportForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  async function handleSubmit(values: SupportRequest) {
    setIsSubmitting(true);
    setError(false);
    setSuccess(false);
    try {
      await createSupportRequest(values);
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  return success ? (
    <SupportFormSuccessView />
  ) : (
    <SupportFormView
      isSubmitting={isSubmitting}
      error={error}
      onSubmit={handleSubmit}
    />
  );
};
