import { RamCalendarEntry } from "../../interfaces";
import { useApiGetPaged } from "./shared";

export function buildVehicleCalendarUrl(vehicleId: string | undefined): string {
  const query = new URLSearchParams();
  query.set("limit", String(25));
  let url = vehicleId ? `/calendar/${vehicleId}` : ``;
  return (url += `?${query.toString()}`);
}
interface UseCalendarRecordingsReturn {
  data: RamCalendarEntry[];
  loading: boolean;
  error: boolean;
  nextPage: () => void;
  prevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}
export function useCalendarRecordings(
  vehicleId: string | undefined
): UseCalendarRecordingsReturn {
  // Fetch and store recordings
  const url = buildVehicleCalendarUrl(vehicleId);
  const {
    data,
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useApiGetPaged(url);

  return {
    data: (data as unknown) as RamCalendarEntry[],
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  };
}
