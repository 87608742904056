import { DateTime } from "luxon";
import React from "react";
import { useJourney, useJourneySummaries } from "../../services/api/journeys";
import { Item } from "../ItemList";
import {
  JourneyContainerProps,
  JourneyContainerWithSelectedProps,
  JourneyListProps,
} from "./interfaces";
import { dataToItems } from "./transformers";
import { JourneyListView } from "./views";

export const JourneyList: React.FC<JourneyListProps> = ({
  vehicle,
  onJourneyClick,
  selectedJourney,
}) => {
  const handleJourneyClick = (item: Item) => {
    onJourneyClick(item.id);
  };

  return (
    <>
      {selectedJourney ? (
        <JourneyListWithSelected
          vehicle={vehicle}
          onJourneyClick={handleJourneyClick}
          selectedJourney={selectedJourney}
        />
      ) : (
        <JourneyListNoSelected
          onJourneyClick={handleJourneyClick}
          vehicle={vehicle}
        />
      )}
    </>
  );
};

const JourneyListNoSelected: React.FC<JourneyContainerProps> = ({
  vehicle,
  onJourneyClick,
}) => {
  const vehicleId = vehicle.id;
  const [date, setDate] = React.useState(DateTime.now().startOf("day").toISO());
  const [data, loading, error] = useJourneySummaries({ vehicleId, date });

  const dataAsItems = React.useMemo(
    () => (data ? dataToItems(data, undefined) : []),
    [data]
  );

  const handleDateChange = (dates: string[]) => {
    setDate(dates[0]);
  };

  /**
   * Select first item in list if available
   */
  React.useEffect(() => {
    if (dataAsItems.length) {
      onJourneyClick(dataAsItems[0]);
    }
  }, [dataAsItems, onJourneyClick]);
  return (
    <JourneyListView
      vehicle={vehicle}
      data={dataAsItems}
      loading={loading}
      error={error}
      onJourneyClick={onJourneyClick}
      dates={[date]}
      onDatesChange={handleDateChange}
    />
  );
};

/**
 * Seperate component due to useJourney hook unable to be used conditionally due to React limitations.
 *
 * It has to be done this way as the deep-linked journey has to be loaded to change the calendar date.
 */
const JourneyListWithSelected: React.FC<JourneyContainerWithSelectedProps> = ({
  vehicle,
  onJourneyClick,
  selectedJourney,
}) => {
  const vehicleId = vehicle.id;
  const journey = useJourney({
    vehicleId,
    journeyId: selectedJourney,
  });
  const [date, setDate] = React.useState(
    journey.data?.tracks[0]
      ? journey.data?.tracks[0].time
      : DateTime.now().startOf("day").toISO()
  );
  const [data, loading, error] = useJourneySummaries({ vehicleId, date });

  const dataAsItems = data ? dataToItems(data, selectedJourney) : [];

  React.useEffect(() => {
    const journeyDate = journey.data?.tracks[0]?.time;
    if (journeyDate !== undefined) {
      setDate(journeyDate);
    }
  }, [journey.data?.tracks]);

  const handleDateChange = (dates: string[]) => {
    setDate(dates[0]);
  };

  return (
    <JourneyListView
      vehicle={vehicle}
      data={dataAsItems}
      loading={loading}
      error={error}
      onJourneyClick={onJourneyClick}
      dates={[date]}
      onDatesChange={handleDateChange}
    />
  );
};
