import React from "react";
import { useParams } from "react-router-dom";

import { useAccount } from "../../services/api/accounts";
import { AccountSummaryParams } from "./interfaces";
import { AccountSummaryView } from "./views";

/**
 * A container to load a summary of an account (fleet)
 */
export const AccountSummary: React.FC = () => {
  const { accountId } = useParams<AccountSummaryParams>();
  const { data, loading, error } = useAccount(accountId);

  return <AccountSummaryView account={data} loading={loading} error={error} />;
};
