import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";

import { useApiGet, apiPost } from "./shared";

import { Journey, JourneySummary } from "../../interfaces";

export function buildJourneySummariesUrl(
  vehicleId: string,
  date: string
): string {
  //Preserve offset using setZone so we can recreate the date
  const iso = DateTime.fromISO(date, { setZone: true });

  return `/journeys/${vehicleId}?date=${iso.toFormat("yyyy-MM-dd")}`;
}

/**
 * Retrieves a list of all journey summaries for a vehicle
 */
export function useJourneySummaries({
  vehicleId,
  date,
}: {
  vehicleId: string;
  date: string;
}): [JourneySummary[] | undefined, boolean, boolean, () => Promise<boolean>] {
  const url = buildJourneySummariesUrl(vehicleId, date);
  return useApiGet<JourneySummary[]>(url);
}

/**
 * Retrieves the detail for a single journey
 */
interface UseJourneyReturn {
  data: Journey | undefined;
  loading: boolean;
  error: boolean;
  requestVideoForDate: (
    date: string,
    location: string,
    heading: number
  ) => Promise<void>;
}
export function useJourney({
  vehicleId,
  journeyId,
}: {
  vehicleId: string;
  journeyId: string;
}): UseJourneyReturn {
  const url = `/journey/${vehicleId}/${journeyId}`;

  async function requestVideoForDate(
    date: string,
    location: string,
    heading: number
  ): Promise<void> {
    const id = uuid();

    const body = {
      id,
      vehicleId,
      date: date,
      location: location,
      heading: heading,
    };

    await apiPost("/event/vod", body);
    revalidate();
  }

  const [data, loading, error, revalidate] = useApiGet<Journey>(url);
  return { data, loading, error, requestVideoForDate };
}
