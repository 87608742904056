import React from "react";

import {
  createResource,
  getResource,
  updateResource,
  useCrud,
  UseCrudReturn,
  useResourceList,
  UseResourceListReturn,
} from "./shared";
import { Account, AccountUpdateBody, AccountAlert } from "../../interfaces";

/**
 * Hook to allow:
 * - retrieving a list of all accounts from the API
 * - deleting accounts (with automatic refetching once complete)
 */
export function useAccounts(): UseResourceListReturn<Account> {
  return useResourceList<Account>("account");
}

/**
 * Hook to allow:
 * - retrieving a single account from the API by ID
 * - updating that account
 */
export function useAccount(
  id: string
): UseCrudReturn<Account, AccountUpdateBody> & {
  updateAlertSettings: (values: AccountAlert) => Promise<void>;
} {
  const crud = useCrud<Account, AccountUpdateBody>({ resource: "account", id });
  const [alertsUpdating, setAlertsUpdating] = React.useState(false);
  const [alertsUpdateSuccess, setAlertsUpdateSuccess] = React.useState(false);
  const [alertsError, setAlertsError] = React.useState(false);

  async function updateAlertSettings(values: AccountAlert) {
    /**
     * Alerts only has a PUT endpoint so we can't use the generic useCrud hook
     */
    try {
      setAlertsUpdating(true);
      await updateResource<AccountAlert>({
        resource: `account/${id}/alerts`,
        data: values,
      });
      setAlertsUpdating(false);
      setAlertsError(false);
      setAlertsUpdateSuccess(true);
    } catch (e) {
      console.error("error updating account alerts", e);
      setAlertsError(true);
      setAlertsUpdateSuccess(false);
    }
  }

  return {
    ...crud,
    isUpdating: crud.isUpdating || alertsUpdating,
    updateSuccess: crud.updateSuccess || alertsUpdateSuccess,
    error: crud.error || alertsError,
    updateAlertSettings,
  };
}

/**
 * Gets an account specified by its ID and refreshes the global state for account lists once
 * done
 */
export async function getAccount(id: string): Promise<Account> {
  return getResource<Account>(`account/${id}`);
}

/**
 * Creates an account and refreshes the global state for account lists once
 * done
 */
export async function createAccount(data: AccountUpdateBody): Promise<void> {
  return createResource<AccountUpdateBody>({ resource: "account", data });
}
