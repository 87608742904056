import { Json } from "../../interfaces";
import { apiDelete, apiPost } from "./shared";

function buildLivestreamUrl(cameraId: string, channel: number) {
  return `/livestream/${cameraId}/stream/${channel}`;
}

function buildHeartbeatUrl(cameraId: string, channel: number) {
  return buildLivestreamUrl(cameraId, channel) + "/heartbeat";
}

export interface StartStreamResponse {
  data: {
    streamingUrl: string;
  };
}

/**
 * router.post('/livestream/:cameraId/stream/:channel/heartbeat', sendHeartbeatController);
 * router.post('/livestream/:cameraId/stream/:channel', startStreamController);
 * router.delete('/livestream/:cameraId/stream/:channel', stopStreamController);
 */
export function requestLivestream(
  cameraId: string,
  channel: number
): Promise<Json> {
  const url = buildLivestreamUrl(cameraId, channel);
  const body = {};

  console.log("POST: ", { url, body });
  return apiPost(url, body);
}

export function stopLivestream(
  cameraId: string,
  channel: number
): Promise<void> {
  const url = buildLivestreamUrl(cameraId, channel);

  return apiDelete(url);
}

export function sendHeartbeat(
  cameraId: string,
  channel: number
): Promise<Json> {
  const url = buildHeartbeatUrl(cameraId, channel);

  return apiPost(url, {});
}
