import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { Item } from "../ItemList";
import {
  VehicleListCustomerProps,
  VehicleListParams,
  VehicleListProps,
} from "./interfaces";
import { VehicleListView, VehicleListAdminPanelView } from "./views";
import {
  useVehicles,
  useVehiclesForCurrentAccount,
} from "../../services/api/vehicles";
import { Vehicle } from "../../interfaces";
import { IntercomCompanyUpdate, update } from "../../services/intercom";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

/**
 * Renders a panel containing a seachable list of vehicles for the account provided via URL and
 * allows:
 * - Navigating to an edit screen for individual vehicles
 * - Via buttons in the panel's header:
 * - Navigating to a create vehicle screen
 * - Deleting one or more vehicles
 *
 * Intended for use by admin users
 */
export const VehicleListAdminPanel: React.FC = () => {
  const { accountId } = useParams<VehicleListParams>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const {
    data,
    loading,
    error,
    del,
    deleting,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useVehicles({
    accountId,
    ...(searchTerm ? { registration: searchTerm } : undefined),
  });

  /**
   * Used to determine whether to show a dismissable badge
   * confirming that a delete operation succeeded
   *
   * TODO: handle error via same mechanism
   */
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);

  const handleDelete = async (items: Item[]) => {
    for (const item of items) {
      await del(item.id);
    }

    setDeleteSuccess(true);
  };

  return (
    <VehicleListAdminPanelView
      data={data}
      loading={loading || deleting}
      error={error}
      accountId={accountId}
      onDelete={handleDelete}
      deleteSuccess={deleteSuccess}
      onSearch={setSearchTerm}
      onNextPage={nextPage}
      onPrevPage={prevPage}
      hasNextPage={hasNextPage}
      hasPrevPage={hasPrevPage}
    />
  );
};

/**
 * Shows a searchable list of vehicles for the account to which the current
 * user belongs
 *
 * Intended for use by non-admin users
 */
export const VehicleListCustomer: React.FC<VehicleListCustomerProps> = ({
  onVehiclesChange,
}) => {
  const history = useHistory();
  const { ctxUser } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = React.useState("");
  const {
    data,
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useVehiclesForCurrentAccount(
    searchTerm
      ? {
          registration: searchTerm,
        }
      : undefined
  );

  /**
   * Report loading of new set of vehicles each time. This allows
   * using this component in companion with another view, e.g. a map, without having to create some sort of global store.
   */
  React.useEffect(() => {
    if (!data) return;
    const buildIntercomUpdateResponse = (
      vehicles: Vehicle[]
    ): IntercomCompanyUpdate => {
      return {
        hasQueclinkCamera: vehicles.some((vehicle) =>
          vehicle.cameras.some((camera) => {
            return "RAM".includes(camera.provider);
          })
        ),
        hasMflCamera: vehicles.some((vehicle) =>
          vehicle.cameras.some((camera) => {
            return "MFL".includes(camera.provider);
          })
        ),
        accountId: ctxUser?.accountId,
      };
    };

    update(buildIntercomUpdateResponse(data));
    onVehiclesChange?.(data);
  }, [ctxUser?.accountId, data, onVehiclesChange]);

  /**
   * Navigate to specific item when selected
   */
  const handleItemClick = (item: Item) => {
    const vehicle = (item as unknown) as Vehicle;

    if (vehicle.status) {
      history.push(`/vehicles/${item.id}`);
    }
  };

  return (
    <VehicleListView
      data={data}
      loading={loading}
      error={error}
      onSearch={setSearchTerm}
      onNextPage={nextPage}
      onPrevPage={prevPage}
      hasNextPage={hasNextPage}
      hasPrevPage={hasPrevPage}
      onItemClick={handleItemClick}
    />
  );
};

export const VehicleList: React.FC<VehicleListProps> = ({
  admin,
  onVehiclesChange,
}) => {
  return admin ? (
    <VehicleListAdminPanel />
  ) : (
    <VehicleListCustomer onVehiclesChange={onVehiclesChange} />
  );
};
