import {
  createResource,
  useApiGetPaged,
  useCrud,
  UseCrudReturn,
  useResourceListPaged,
  UseResourceListPagedReturn,
} from "./shared";

import { Vehicle, VehicleUpdateBody } from "../../interfaces";

/**
 * Hook to allow:
 * - retrieving a list of all vehicles for an account from the API
 * - deleting vehicles (with automatic refetching once complete)
 */
export function useVehicles({
  accountId,
  registration,
}: {
  accountId: string;
  registration?: string;
}): UseResourceListPagedReturn<Vehicle> {
  let url = `account/${accountId}/vehicles`;
  if (registration) url += `?registration=${registration}`;

  return useResourceListPaged<Vehicle>("vehicle", url);
}

/**
 * Hook to allow:
 * - retrieving a single vehicle from the API by ID
 * - updating that vehicle
 */
export function useVehicle(
  id: string
): UseCrudReturn<Vehicle, VehicleUpdateBody> {
  return useCrud<Vehicle, VehicleUpdateBody>({ resource: "vehicle", id });
}

/**
 * Creates a vehicle and refreshes the global state for vehicle lists once done
 */
export async function createVehicle(data: VehicleUpdateBody): Promise<void> {
  return createResource<VehicleUpdateBody>({ resource: "vehicle", data });
}

/**
 * Hook to retrieve vehicles from the API for the account to which the current
 * user belongs
 */
interface UseVehiclesReturn {
  data?: Vehicle[];
  loading: boolean;
  error: boolean;
  nextPage: () => void;
  prevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}
export function useVehiclesForCurrentAccount({
  registration,
  pageSize,
}: { registration?: string; pageSize?: number } = {}): UseVehiclesReturn {
  // Build URL, including any parameters
  let url = "/vehicles";
  const query = new URLSearchParams();
  if (pageSize) query.set("limit", String(pageSize));
  if (registration) query.set("registration", registration);
  if (Array.from(query.keys()).length > 0) url += `?${query.toString()}`;

  const {
    data,
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useApiGetPaged(url);

  return {
    data: data as Vehicle[] | undefined,
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  };
}
