import React from "react";
import { useParams } from "react-router-dom";

import { useEvent } from "../../services/api/events";
import { useTranslation } from "../../hooks/i18n";
import { formatEventDataForDisplay } from "../EventList/transformers";

import { EventDetailParams, EventDetailProps } from "./interfaces";
import { EventDetailView } from "./views";
import { useStyles } from "./styles";
import { EventContext } from "./EventContext";

export const EventDetail: React.FC<EventDetailProps> = ({ onBack }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { vehicleId, eventId } = useParams<EventDetailParams>();
  const { event, loading, error, requestImage, requestVideo } = useEvent({
    vehicleId,
    eventId,
  });

  const localizedEvent = event
    ? formatEventDataForDisplay(t, event)
    : undefined;

  return (
    <EventContext.Provider
      value={{
        event,
        requestImage,
        requestVideo,
      }}
    >
      <div className={classes.container}>
        <EventDetailView
          loading={loading}
          error={error}
          event={localizedEvent}
          onBack={onBack}
        />
      </div>
    </EventContext.Provider>
  );
};
