import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { FormikErrors } from "formik";
import { HyperlapseRequestBody } from "../../../interfaces";
import { requestHyperlapse } from "../../../services/api/events";
import { LocalizedText } from "../../LocalizedText";
import { CloseButton } from "../../Panel";
import { HyperlapseRequestModalProps } from "./interfaces";
import { calculateDatesFromDateAndDuration } from "./transformers";
import { HyperlapseRequestForm } from "./views";

export const HyperlapseRequestModal: React.FC<HyperlapseRequestModalProps> = ({
  show,
  vehicleId,
  start,
  end,
  startLocation,
  onClose,
  onHyperlapseRequestSuccess,
}) => {
  const handleSubmit = (
    values: { date: string; duration: string; channel: string },
    setSubmitting: (is: boolean) => void,
    setErrors: (
      errors: FormikErrors<{ date: string; duration: string; channel: string }>
    ) => void,
    resetForm: () => void
  ) => {
    const { date, duration, channel } = values;
    const { startDate, endDate } = calculateDatesFromDateAndDuration(
      date,
      Number(duration)
    );
    const body: HyperlapseRequestBody = {
      startDate: startDate,
      endDate: endDate,
      vehicleId: vehicleId,
      startLocation: startLocation,
      channel: Number(channel),
    };

    requestHyperlapse(body)
      .then((res) => {
        onHyperlapseRequestSuccess();
        resetForm();
      })
      .catch((err) => {
        console.error(err);
        setErrors(err);
      })
      .then(() => setSubmitting(false));
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      open={show}
      onClose={onClose}
    >
      <DialogTitle id="simple-dialog-title" disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            <LocalizedText text="hyperlapse_request_title" />
          </Typography>
          <CloseButton onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <HyperlapseRequestForm
          start={start}
          end={end}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};
