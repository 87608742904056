import { useCameraStatuses } from "../../services/api/cameraStatuses";
import { ReportListView } from "./views";

export const ReportList: React.FC = () => {
  const { data, loading, error } = useCameraStatuses();

  return (
    <>
      <ReportListView data={data} loading={loading} error={error} />
    </>
  );
};
