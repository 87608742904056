import faker from "faker";
import { Account } from "../interfaces";

export function makeAccount(data: Partial<Account> = {}): Account {
  return {
    id: faker.datatype.uuid(),
    name: `Test account - ${faker.lorem.words(3)}`,
    salesforceId: faker.datatype.uuid(),
    providers: ["MFL", "RAM"],
    alerts: {
      drivingStyle: ["MODERATE"],
      impacts: true,
      recipients: [faker.internet.exampleEmail()],
    },
    ...data,
  };
}

export function makeAccounts(
  num: number,
  dataFunc?: () => Partial<Account>
): Account[] {
  return Array(num)
    .fill("")
    .map((_) => makeAccount(dataFunc ? dataFunc() : {}));
}
