// Container should contain any external API requests
import React from "react";
import { RequestVideoDialogProps } from ".";
import { requestVod } from "../../../services/api/events";
import { RequestVideoDialogView } from "./views";

export const RequestVideoDialog: React.FC<RequestVideoDialogProps> = (
  props
) => {
  const handleRequestVideo = (
    vehicleId: string,
    date: string,
    location: string | undefined,
    heading: number,
    address: string | null,
    utc: string,
    channel: number
  ) => {
    return requestVod({
      vehicleId,
      date,
      location,
      heading,
      address,
      utc,
      channel,
    });
  };
  return (
    <RequestVideoDialogView {...props} onRequestVideo={handleRequestVideo} />
  );
};
