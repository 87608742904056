import React from "react";
import { useParams } from "react-router-dom";

import { Item } from "../ItemList";
import { UserListParams } from "./interfaces";
import { UserListView } from "./views";
import { useUsers } from "../../services/api/users";

/**
 * Shows a list of users and allows:
 * - Navigating to an edit screen for individual accounts
 * - Deleting one or more accounts
 */
export const UserList: React.FC = () => {
  const { accountId } = useParams<UserListParams>();
  const { data, loading, error, del, deleting } = useUsers(accountId);
  /**
   * Used to determine whether to show a dismissable badge
   * confirming that a delete operation succeeded
   *
   * TODO: handle error via same mechanism
   */
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);

  const handleDelete = async (items: Item[]) => {
    for (const item of items) {
      await del(item.id);
    }

    setDeleteSuccess(true);
  };

  return (
    <div>
      <UserListView
        data={data}
        accountId={accountId}
        loading={loading || deleting}
        error={error}
        onDelete={handleDelete}
        deleteSuccess={deleteSuccess}
      />
    </div>
  );
};
