import React from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../hooks/auth";

function warnAboutMissingTrackingDetails() {
  console.warn(
    "[useTracking] Tracking disabled -  please specify a tracking ID when calling useTracking, or set `REACT_APP_GA_MEASUREMENT_ID` in env."
  );
}

function warnAboutMissingGtag() {
  console.warn(
    "window.gtag is missing - tracking disabled. The Google Analytics snippet is probably missing from the HTML template"
  );
}

export const useTracking = (
  trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID
): void => {
  const { listen } = useHistory();
  const { user } = useCurrentUser();

  /**
   * Set the user's email as the user_id in Google Analytics
   */
  React.useEffect(() => {
    if (!user || !trackingId) return;
    if (!window.gtag) {
      warnAboutMissingGtag();
      return;
    }
    window.gtag("config", trackingId, {
      user_id: user.email,
    });
  }, [user, trackingId]);

  React.useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) {
        warnAboutMissingGtag();
        return;
      }
      if (!trackingId) {
        warnAboutMissingTrackingDetails();
        return;
      }

      window.gtag("config", trackingId, { page_path: location.pathname });
    });

    return unlisten;
  }, [trackingId, listen]);
};
