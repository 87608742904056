import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { AccountUpdateBody } from "../../interfaces";
import { createAccount, useAccount } from "../../services/api/accounts";
import {
  AccountFormParams,
  EditAccountFormProps,
  SubmittingAccountFormData,
} from "./interfaces";
import { formValuesToRequestBody } from "./transformers";
import { AccountFormView } from "./views";

/**
 * A container to handle creating or editing an account (fleet)
 *
 * It decides whether to show a create or edit form based on whether
 * there is an account ID present in the URL.
 */
export const AccountForm: React.FC = () => {
  const { accountId } = useParams<AccountFormParams>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Typing this is a bit arduous
  const location = useLocation<any>();

  return accountId ? (
    <EditAccountForm id={accountId} created={location.state?.created} />
  ) : (
    <CreateAccountForm />
  );
};

const CreateAccountForm: React.FC = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success] = React.useState(false);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (data: SubmittingAccountFormData) => {
    setIsSubmitting(true);
    try {
      const id = uuid();
      await createAccount({
        ...formValuesToRequestBody(data),
        id,
      } as AccountUpdateBody);
      /**
       * Navigate to summary screen for the newly-created account
       */
      history.push(`/accounts/summary/${id}`);
    } catch (e) {
      setError(true);
      setIsSubmitting(false);
    }
  };

  return (
    <AccountFormView
      isSubmitting={isSubmitting}
      error={error}
      success={success}
      onSubmit={handleSubmit}
    />
  );
};

const EditAccountForm: React.FC<EditAccountFormProps> = ({ id, created }) => {
  const {
    data,
    loading,
    error,
    update,
    isUpdating,
    updateSuccess,
  } = useAccount(id);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: SubmittingAccountFormData) => {
    update({ ...formValuesToRequestBody(values), id } as AccountUpdateBody);
  };

  return (
    <AccountFormView
      account={data ?? undefined}
      accountId={data?.id}
      loading={loading}
      error={error}
      onSubmit={handleSubmit}
      isSubmitting={isUpdating}
      success={created || updateSuccess}
      isEdit
    />
  );
};
