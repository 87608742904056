import {
  createResource,
  useCrud,
  UseCrudReturn,
  useResourceList,
  UseResourceListReturn,
} from "./shared";

import { Camera, CameraUpdateBody } from "../../interfaces";

/**
 * Hook to allow:
 * - retrieving a list of all cameras for an account from the API
 * - deleting cameras (with automatic refetching once complete)
 */
export function useCameras(accountId: string): UseResourceListReturn<Camera> {
  return useResourceList<Camera>("camera", `account/${accountId}/cameras`);
}

/**
 * Hook to allow:
 * - retrieving a single camera from the API by ID
 * - updating that camera
 */
export function useCamera(id: string): UseCrudReturn<Camera, CameraUpdateBody> {
  return useCrud<Camera, CameraUpdateBody>({ resource: "camera", id });
}

/**
 * Creates a camera and refreshes the global state for camera lists once done
 */
export async function createCamera(data: CameraUpdateBody): Promise<void> {
  return createResource<CameraUpdateBody>({ resource: "camera", data });
}
