import { cache } from "swr";
import { Auth } from "@aws-amplify/auth";
import { CognitoUserWithAttributes } from "../hooks/auth";

export function signIn(
  username: string,
  password: string
): ReturnType<typeof Auth.signIn> {
  /**
   * Clear SWR's cache to avoid leaking data between sessions
   */
  cache.clear();
  /**
   * Sign in via Amplify SDK
   */
  return Auth.signIn(username, password);
}

export async function signOut(): Promise<void> {
  /**
   * Clear SWR's cache to avoid leaking data between sessions
   */
  cache.clear();
  /**
   * Sign out via Amplify SDK
   */
  await Auth.signOut();
  /**
   * Redirect to base URL so that next user has a fresh start
   */
  window.location.pathname = "/";
}

/**
 * Registers the user's new password when forced to do so after first sign in
 */
export function completeNewPassword(
  user: CognitoUserWithAttributes,
  password: string
): ReturnType<typeof Auth.completeNewPassword> {
  return Auth.completeNewPassword(user, password);
}

/**
 * Asks Cognito to send a password reset email to the user
 */
export function requestPasswordReset(
  username: string
): ReturnType<typeof Auth.forgotPassword> {
  return Auth.forgotPassword(username);
}

/**
 * Changes the users password using a password reset code
 */
export function completePasswordReset(
  username: string,
  code: string,
  newPassword: string
): ReturnType<typeof Auth.forgotPasswordSubmit> {
  return Auth.forgotPasswordSubmit(username, code, newPassword);
}
